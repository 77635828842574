import React, { FC } from 'react';
import { PERMISSIONS } from '../../../../common';
import { Pages, NavMenuOptions } from '../../../../common/types/UserPreferences';
import { Settings } from '../components/Pages/Settings';
import GlobalAlertsPage from '../components/Pages/GlobalAlerts';
import { BetaFeatures } from '../components/Pages/BetaFeatures';
import { UserPreferencesModal } from '../UserPreferencesModal';

const SettingsConfig = {
  title: 'Settings',
  pages: {
    [Pages.SETTINGS]: {
      title: 'Settings',
      Component: Settings
    },
    [Pages.GLOBAL_ALERTS]: {
      title: 'Global Alerts',
      Component: GlobalAlertsPage,
      permissions: PERMISSIONS.canEditWorklistGlobalAlerts
    },
    [Pages.BETA_FEATURES]: {
      title: 'Beta Features',
      Component: BetaFeatures,
      permissions: PERMISSIONS.canViewWorkflowBetaFeatures
    }
  }
} as NavMenuOptions;

interface Props {
  openModalStatus: { open: boolean; openTab: string };
  hide: () => void;
}

export const UserPreferencesSettingsModal: FC<Props> = ({ openModalStatus, hide }) => {
  return (
    <UserPreferencesModal
      hide={hide}
      openModalStatus={openModalStatus}
      navMenuOptions={SettingsConfig}
    />
  );
};
