import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Text } from '@tidbits/react-tidbits';
import { FormType, FeatureSetting } from '../../../../../common/types/UserPreferences';
import UserPreferenceRedux, {
  SettingsType,
  UserPreferencesType
} from '@helper-hooks/user-preferences-redux-action';

import { userHasPermission } from '@helper-hooks/general-utils';

import { getUserPermissions } from '../../../../../store/user/userSelector';
import { getUserPreferences } from '../../../../../store/userPreferences/userPreferencesSelector';
import { RadioSelection } from './RadioSelection';
import { ToggleSwitch } from './ToggleSwitch';
import { hostResolver } from '../../../../../utils/envResolver';
import { getWorkflowTypeUserPreferenceKey } from '../../utils/utils';
import { DropdownSelection } from './DropdownSelection';

type Props = {
  value: string | boolean | number;
  settingsType: SettingsType;
  workflowType: string;
} & FeatureSetting;

export const FormInputs: FC<Props> = ({
  value,
  permissions,
  settingsType,
  workflowType,
  propertyKey,
  options,
  defaultValue,
  type,
  label,
  envEnabled,
  localChange,
  localChangeString
}) => {
  const userPermissions = useSelector(getUserPermissions);
  const dispatch = useDispatch();
  const currentPreferences = useSelector(getUserPreferences);

  const handleOnChange = (value: string | boolean | number) => {
    const workflowTypeKey = getWorkflowTypeUserPreferenceKey(workflowType);
    const betaFeaturePreference = {
      [workflowTypeKey]: {
        [propertyKey]: value
      }
    } as UserPreferencesType;
    console.log({ betaFeaturePreference });
    dispatch(
      UserPreferenceRedux.userPreferencesAction.putUserPreferenceRequest(
        'workflow',
        settingsType,
        betaFeaturePreference,
        currentPreferences
      )
    );

    if (localChange) {
      if (value === 'on') {
        window.localStorage.setItem(localChangeString, 'true');
      } else {
        window.localStorage.removeItem(localChangeString);
      }
    }
  };

  const renderFormType = (type: FormType) => {
    // TODO need to refactor this out since not all forms will be reliant on the beta feature, need to find a way to identify the values
    switch (type) {
      case FormType.RADIO:
        return (
          <RadioSelection
            defaultValue={(value as string) || (defaultValue as string)}
            options={options || []}
            handleOnChange={handleOnChange}
          />
        );
      case FormType.TOGGLE:
        return (
          <ToggleSwitch
            defaultValue={(value as boolean) || (defaultValue as boolean)}
            handleOnChange={handleOnChange}
          />
        );
      case FormType.DROPDOWN_SELECTION:
        return (
          <DropdownSelection
            defaultValue={(value as string | number) || (defaultValue as number | string)}
            options={options || []}
            handleOnChange={handleOnChange}
            label={label}
          />
        );
      default:
        return null;
    }
  };

  const isEnvEnabled = envEnabled && !envEnabled.includes(hostResolver());
  if (!userHasPermission(userPermissions, permissions) && isEnvEnabled) {
    return null;
  }

  return (
    <Box>
      {type !== FormType.DROPDOWN_SELECTION && <Text sb="spacer10">{label}</Text>}
      {renderFormType(type)}
    </Box>
  );
};
