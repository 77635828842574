import { TaskListItem } from '../../common/types/TaskList';

export const WORKLIST = 'WORKLIST';

export enum GetWorklistActionTypes {
  GET_WORKLIST = `GET_WORKLIST`,
  GET_WORKLIST_SUCCESS = 'GET_WORKLIST_SUCCESS',
  GET_WORKLIST_REQUEST = 'GET_WORKLIST_REQUEST',
  GET_WORKLIST_FAILURE = 'GET_WORKLIST_FAILURE'
}

export enum SetWorklistQueryParamsTypes {
  SET_WORKLIST_QUERY_PARAMS = 'SET_WORKLIST_QUERY_PARAMS',
  SET_WORKLIST_QUERY_PARAMS_SUCCESS = 'SET_WORKLIST_QUERY_PARAMS_SUCCESS',
  SET_WORKLIST_QUERY_PARAMS_REQUEST = 'SET_WORKLIST_QUERY_PARAMS_REQUEST',
  SET_WORKLIST_QUERY_PARAMS_FAILURE = 'SET_WORKLIST_QUERY_PARAMS_FAILURE'
}

export enum GetInfiniteWorklistTypes {
  GET_INFINITE_WORKLIST = 'GET_INFINITE_WORKLIST',
  GET_INFINITE_WORKLIST_SUCCESS = 'GET_INFINITE_WORKLIST_SUCCESS',
  GET_INFINITE_WORKLIST_REQUEST = 'GET_INFINITE_WORKLIST_REQUEST',
  GET_INFINITE_WORKLIST_FAILURE = 'GET_INFINITE_WORKLIST_FAILURE'
}

export enum GetSortWorklistActionTypes {
  GET_SORT_WORKLIST = 'GET_SORT_WORKLIST',
  GET_SORT_WORKLIST_SUCCESS = 'GET_SORT_WORKLIST_SUCCESS',
  GET_SORT_WORKLIST_REQUEST = 'GET_SORT_WORKLIST_REQUEST',
  GET_SORT_WORKLIST_FAILURE = 'GET_SORT_WORKLIST_FAILURE'
}

export enum SetWorklistSelectedTasksTypes {
  SET_WORKLISTED_SELECTED_TASKS = 'SET_WORKLISTED_SELECTED_TASKS'
}

export enum ExportWorklistTypes {
  EXPORT_WORKLIST_REQUEST = 'EXPORT_WORKLIST_REQUEST',
  EXPORT_WORKLIST_SUCCESS = 'EXPORT_WORKLIST_SUCCESS',
  EXPORT_WORKLIST_FAILURE = 'EXPORT_WORKLIST_FAILURE'
}

export enum ReportWorklistTypes {
  REPORT_WORKLIST_REQUEST = 'REPORT_WORKLIST_REQUEST',
  REPORT_WORKLIST_SUCCESS = 'REPORT_WORKLIST_SUCCESS',
  REPORT_WORKLIST_FAILURE = 'REPORT_WORKLIST_FAILURE'
}

export enum ImportWorklistTypes {
  IMPORT_WORKLIST_REQUEST = 'IMPORT_WORKLIST_REQUEST',
  IMPORT_WORKLIST_SUCCESS = 'IMPORT_WORKLIST_SUCCESS',
  IMPORT_WORKLIST_FAILURE = 'IMPORT_WORKLIST_FAILURE',
  IMPORT_WORKLIST_RESET = 'IMPORT_WORKLIST_RESET'
}

export enum ValidateWorklistTypes {
  VALIDATE_WORKLIST_REQUEST = 'VALIDATE_WORKLIST_REQUEST',
  VALIDATE_WORKLIST_SUCCESS = 'VALIDATE_WORKLIST_SUCCESS',
  VALIDATE_WORKLIST_FAILURE = 'VALIDATE_WORKLIST_FAILURE'
}

export enum UpdateWorklistItemTypes {
  UPDATE_WORKLIST_ITEMS_SUCCESS = 'UPDATE_WORKLIST_ITEMS'
}

export type GetWorklistTasksRequestBothPayload =
  | GetWorklistTasksRequestPayload
  | GetWorklistTasksRequestV2FiltersPayload;

export interface GetWorklistTasksRequestPayload {
  cursor: string;
  sortDirection: string;
  sortField: string;
  searchTerm?: string;
  pageSize?: number;
  filters?: any[];
}

export interface GetWorklistTasksRequestV2FiltersPayload {
  query: string;
  filters: any[];
  pageSize: number;
}

export interface GetWorklistTasksSuccessPayload {
  cursor: string;
  items: TaskListItem[];
  totalItems: number;
  selectedIds: Array<string>;
}

export type SetWorklistQueryParamsBothPayload =
  | SetWorklistQueryParamsPayload
  | SetWorklistQueryParamsV2FiltersPayload;
export interface SetWorklistQueryParamsPayload {
  cursor: string;
  sortDirection: string;
  sortField: string;
  searchTerm?: string;
  filters: any;
  query?: string;
}

export interface SetWorklistQueryParamsV2FiltersPayload {}

export interface GetSortWorklistRequestPayload {
  cursor: string;
  items: TaskListItem[];
  totalItems: number;
  selectedIds: Array<string>;
}
export interface GetWorklistSelectedTasksPayload {
  selectedIds: Array<string>;
}

export interface SetWorklistSelectedTasksPayload {
  selectedIds: Array<string>;
}
interface Task {
  id: string;
}
export interface ImportWorklistPayload {
  type: string;
  file: File;
  items: Task[];
  skipAdvanceItems: Task[];
}

export interface ValidateWorklistPayload {
  type: string;
  file: File;
}

export interface GetWorklistTasksRequestAction {
  type: GetWorklistActionTypes.GET_WORKLIST_REQUEST;
  payload: GetWorklistTasksRequestBothPayload;
}

export interface GetWorklistTasksSuccessAction {
  type: GetWorklistActionTypes.GET_WORKLIST_SUCCESS;
  payload: GetWorklistTasksSuccessPayload;
}
export interface GetWorklistTasksFailureAction {
  type: GetWorklistActionTypes.GET_WORKLIST_FAILURE;
  error: any;
}

export interface SetWorklistQueryParamsAction {
  type: SetWorklistQueryParamsTypes.SET_WORKLIST_QUERY_PARAMS_SUCCESS;
  payload: SetWorklistQueryParamsPayload;
}

export interface GetInfiniteWorklistRequestAction {
  type: GetInfiniteWorklistTypes.GET_INFINITE_WORKLIST_REQUEST;
  payload: SetWorklistQueryParamsBothPayload;
}

export interface GetInfiniteWorklistSuccessAction {
  type: GetInfiniteWorklistTypes.GET_INFINITE_WORKLIST_SUCCESS;
  payload: GetWorklistTasksSuccessPayload;
}

export interface GetInfniteWorklistFailureAction {
  type: GetInfiniteWorklistTypes.GET_INFINITE_WORKLIST_FAILURE;
  error: any;
}

export interface GetSortWorklistRequestAction {
  type: GetSortWorklistActionTypes.GET_SORT_WORKLIST_REQUEST;
  payload: GetWorklistTasksRequestPayload;
}

export interface GetSortWorklistSuccessAction {
  type: GetSortWorklistActionTypes.GET_SORT_WORKLIST_SUCCESS;
  payload: GetSortWorklistRequestPayload;
}

export interface GetSortWorklistFailureAction {
  type: GetSortWorklistActionTypes.GET_SORT_WORKLIST_FAILURE;
  error: any;
}

export interface SetWorklistSelectedTasksAction {
  type: SetWorklistSelectedTasksTypes.SET_WORKLISTED_SELECTED_TASKS;
  payload: SetWorklistSelectedTasksPayload;
}

export interface ExportWorklistRequestAction {
  type: ExportWorklistTypes.EXPORT_WORKLIST_REQUEST;
  payload: SetWorklistSelectedTasksPayload;
}

export interface ExportWorklistSuccessAction {
  type: ExportWorklistTypes.EXPORT_WORKLIST_SUCCESS;
}

export interface ExportWorklistFailureAction {
  type: ExportWorklistTypes.EXPORT_WORKLIST_FAILURE;
  error: any;
}

export interface ReportWorklistRequestAction {
  type: ReportWorklistTypes.REPORT_WORKLIST_REQUEST;
  payload: SetWorklistSelectedTasksPayload;
}

export interface ReportWorklistSuccessAction {
  type: ReportWorklistTypes.REPORT_WORKLIST_SUCCESS;
}

export interface ReportWorklistFailureAction {
  type: ReportWorklistTypes.REPORT_WORKLIST_FAILURE;
  error: any;
}

export interface ImportWorklistRequestAction {
  type: ImportWorklistTypes.IMPORT_WORKLIST_REQUEST;
  payload: ImportWorklistPayload;
}

export interface ImportWorklistSuccessAction {
  type: ImportWorklistTypes.IMPORT_WORKLIST_SUCCESS;
  payload: any;
}

export interface ImportWorklistFailureAction {
  type: ImportWorklistTypes.IMPORT_WORKLIST_FAILURE;
  error: any;
}

export interface ValidateWorklistRequestAction {
  type: ValidateWorklistTypes.VALIDATE_WORKLIST_REQUEST;
  payload: ValidateWorklistPayload;
}

export interface ValidateWorklistSuccessAction {
  type: ValidateWorklistTypes.VALIDATE_WORKLIST_SUCCESS;
  payload: any;
}

export interface ValidateWorklistFailureAction {
  type: ValidateWorklistTypes.VALIDATE_WORKLIST_FAILURE;
  error: any;
}

export interface UpdateWorklistItemsPayload {
  items: TaskListItem[];
}

export interface UpdateWorklistItemsAction {
  type: UpdateWorklistItemTypes.UPDATE_WORKLIST_ITEMS_SUCCESS;
  payload: UpdateWorklistItemsPayload;
}

export type WorklistActionTypes =
  | GetWorklistTasksRequestAction
  | GetWorklistTasksSuccessAction
  | SetWorklistQueryParamsAction
  | GetInfiniteWorklistSuccessAction
  | GetSortWorklistRequestAction
  | GetSortWorklistSuccessAction
  | ExportWorklistRequestAction
  | SetWorklistSelectedTasksAction
  | ReportWorklistRequestAction
  | ImportWorklistSuccessAction
  | ImportWorklistFailureAction
  | ValidateWorklistSuccessAction
  | ValidateWorklistRequestAction
  | UpdateWorklistItemsAction;
