import {
  GetInfiniteWorklistRequestAction,
  GetInfiniteWorklistSuccessAction,
  GetInfniteWorklistFailureAction,
  GetInfiniteWorklistTypes,
  GetSortWorklistActionTypes,
  GetSortWorklistRequestAction,
  GetSortWorklistRequestPayload,
  GetSortWorklistSuccessAction,
  GetSortWorklistFailureAction,
  GetWorklistActionTypes,
  GetWorklistTasksRequestAction,
  GetWorklistTasksRequestPayload,
  GetWorklistTasksSuccessAction,
  GetWorklistTasksSuccessPayload,
  GetWorklistTasksFailureAction,
  SetWorklistQueryParamsAction,
  SetWorklistQueryParamsPayload,
  SetWorklistQueryParamsTypes,
  SetWorklistSelectedTasksAction,
  SetWorklistSelectedTasksPayload,
  SetWorklistSelectedTasksTypes,
  ExportWorklistTypes,
  ExportWorklistRequestAction,
  ExportWorklistFailureAction,
  ReportWorklistTypes,
  ReportWorklistRequestAction,
  ReportWorklistFailureAction,
  ImportWorklistTypes,
  ImportWorklistRequestAction,
  ImportWorklistFailureAction,
  ValidateWorklistTypes,
  ValidateWorklistRequestAction,
  ValidateWorklistFailureAction,
  ImportWorklistSuccessAction,
  ReportWorklistSuccessAction,
  ExportWorklistSuccessAction,
  ValidateWorklistSuccessAction,
  GetWorklistTasksRequestBothPayload,
  SetWorklistQueryParamsBothPayload,
  UpdateWorklistItemsAction,
  UpdateWorklistItemTypes
} from './worklistActionTypes';

export const getWorklistTasksRequest = (
  worklistTasksPayload: GetWorklistTasksRequestBothPayload
): GetWorklistTasksRequestAction => ({
  type: GetWorklistActionTypes.GET_WORKLIST_REQUEST,
  payload: worklistTasksPayload
});

export const getWorklistSuccess = (
  worklistData: GetWorklistTasksSuccessPayload
): GetWorklistTasksSuccessAction => ({
  type: GetWorklistActionTypes.GET_WORKLIST_SUCCESS,
  payload: worklistData
});

export const getWorklistFailure = (error: any): GetWorklistTasksFailureAction => ({
  type: GetWorklistActionTypes.GET_WORKLIST_FAILURE,
  error
});

export const getInfiniteWorklistRequest = (
  infiniteRequestPayload: SetWorklistQueryParamsBothPayload
): GetInfiniteWorklistRequestAction => ({
  type: GetInfiniteWorklistTypes.GET_INFINITE_WORKLIST_REQUEST,
  payload: infiniteRequestPayload
});

export const getInfiniteWorklistSuccess = (
  infiniteRequestPayload: GetWorklistTasksSuccessPayload
): GetInfiniteWorklistSuccessAction => ({
  type: GetInfiniteWorklistTypes.GET_INFINITE_WORKLIST_SUCCESS,
  payload: infiniteRequestPayload
});

export const getInfiniteWorklistFailure = (error: any): GetInfniteWorklistFailureAction => ({
  type: GetInfiniteWorklistTypes.GET_INFINITE_WORKLIST_FAILURE,
  error
});

export const setWorklistQueryParams = (
  queryParams: SetWorklistQueryParamsPayload
): SetWorklistQueryParamsAction => ({
  type: SetWorklistQueryParamsTypes.SET_WORKLIST_QUERY_PARAMS_SUCCESS,
  payload: queryParams
});

export const getSortWorklistRequest = (
  sortWorklistData: GetWorklistTasksRequestPayload
): GetSortWorklistRequestAction => ({
  type: GetSortWorklistActionTypes.GET_SORT_WORKLIST_REQUEST,
  payload: sortWorklistData
});

export const getSortWorklistSuccess = (
  sortWorklistData: GetSortWorklistRequestPayload
): GetSortWorklistSuccessAction => ({
  type: GetSortWorklistActionTypes.GET_SORT_WORKLIST_SUCCESS,
  payload: sortWorklistData
});

export const getSortWorklistFailure = (error: any): GetSortWorklistFailureAction => ({
  type: GetSortWorklistActionTypes.GET_SORT_WORKLIST_FAILURE,
  error
});

export const setSelectedWorklistTasks = (
  selectedTasks: SetWorklistSelectedTasksPayload
): SetWorklistSelectedTasksAction => ({
  type: SetWorklistSelectedTasksTypes.SET_WORKLISTED_SELECTED_TASKS,
  payload: selectedTasks
});

export const exportWorklistRequest = (
  selectedTasks: SetWorklistSelectedTasksPayload
): ExportWorklistRequestAction => ({
  type: ExportWorklistTypes.EXPORT_WORKLIST_REQUEST,
  payload: selectedTasks
});

export const exportWorklistSuccess = (): ExportWorklistSuccessAction => ({
  type: ExportWorklistTypes.EXPORT_WORKLIST_SUCCESS
});

export const exportWorklistFailure = (error: any): ExportWorklistFailureAction => ({
  type: ExportWorklistTypes.EXPORT_WORKLIST_FAILURE,
  error
});

export const reportWorklistRequest = (
  selectedTasks: SetWorklistSelectedTasksPayload
): ReportWorklistRequestAction => ({
  type: ReportWorklistTypes.REPORT_WORKLIST_REQUEST,
  payload: selectedTasks
});

export const reportWorklistSuccess = (): ReportWorklistSuccessAction => ({
  type: ReportWorklistTypes.REPORT_WORKLIST_SUCCESS
});

export const reportWorklistFailure = (error: any): ReportWorklistFailureAction => ({
  type: ReportWorklistTypes.REPORT_WORKLIST_FAILURE,
  error
});

export const importWorklistRequest = (payload: any): ImportWorklistRequestAction => ({
  type: ImportWorklistTypes.IMPORT_WORKLIST_REQUEST,
  payload
});

export const importWorklistReset = () => ({
  type: ImportWorklistTypes.IMPORT_WORKLIST_RESET
});

export const importWorklistSuccess = (payload: any): ImportWorklistSuccessAction => ({
  type: ImportWorklistTypes.IMPORT_WORKLIST_SUCCESS,
  payload
});

export const importWorklistFailure = (error: any): ImportWorklistFailureAction => ({
  type: ImportWorklistTypes.IMPORT_WORKLIST_FAILURE,
  error
});

export const validateWorklistRequest = (payload: any): ValidateWorklistRequestAction => ({
  type: ValidateWorklistTypes.VALIDATE_WORKLIST_REQUEST,
  payload
});

export const validateWorklistSuccess = (payload: any): ValidateWorklistSuccessAction => ({
  type: ValidateWorklistTypes.VALIDATE_WORKLIST_SUCCESS,
  payload
});

export const validateWorklistFailure = (error: any): ValidateWorklistFailureAction => ({
  type: ValidateWorklistTypes.VALIDATE_WORKLIST_FAILURE,
  error
});

export const updateWorklistItemsSuccess = (payload: any): UpdateWorklistItemsAction => ({
  type: UpdateWorklistItemTypes.UPDATE_WORKLIST_ITEMS_SUCCESS,
  payload
});
