import { TaskListItemV1, TaskListItemV2, TaskListV2 } from '../common/types/TaskList';
// TODO: Make changes to use v2 api

export const mapV2WorklistResponseToV1 = (v2WorklistResponse: TaskListV2) => {
  return {
    items: v2WorklistResponse.data.map((item) => remapV2WorklistItemToV1(item)),
    batchSize: v2WorklistResponse.pageSize,
    totalItems: v2WorklistResponse.totalItems,
    cursor: v2WorklistResponse.cursor
  };
};

function remapV2WorklistItemToV1(v2: TaskListItemV2): TaskListItemV1 {
  const {
    attributes,
    createdAt,
    description,
    dueDate,
    taskURI,
    state,
    title,
    updatedAt,
    workflowType
  } = v2;

  const v1: TaskListItemV1 = {
    assigneeEmail: attributes?.assigneeEmail?.[0] || '',
    assigneeName: attributes?.assigneeName?.[0] || '',
    assigneeUserRealmIdentity: v2.assigneeFullyQualifiedIdentifier,
    assigneeVendor: attributes?.assigneeVendor?.[0] || '',
    confidential: attributes?.confidential?.[0] === 'true',
    contentId: attributes?.contentId?.[0] || '',
    contentType: attributes?.contentType?.[0] || '',
    createdAt,
    description,
    dueDate,
    genres: attributes?.genres || [],
    imageToken: null,
    iso3aCountryCodes: attributes?.iso3aCountryCodes || [],
    isRead_bool: attributes?.isRead_bool?.[0] || 'true',
    hasIsRead_boolProperty:
      attributes?.hasOwnProperty('isRead_bool') && attributes?.isRead_bool?.[0] !== null,
    isActive: attributes?.taskStatus?.[0] !== 'ARCHIVED',
    locale: attributes?.locale?.[0] || '',
    namespace: v2.workspaceIdentifier,
    namespaceSpecificMap: null,
    priority: attributes?.priority?.[0] === 'true',
    rating: null,
    readAt_date_time: attributes?.readAt_date_time?.[0] || '',
    state,
    statusUpdatedAt: attributes?.statusUpdatedAt?.[0] || '',
    taskDri: attributes?.taskDri?.[0] || '',
    taskGuuid: v2.taskId,
    taskStatus: attributes?.taskStatus?.[0] || '',
    taskURI,
    department_text_full_match: attributes?.department_text_full_match?.[0] || '',
    title,
    updatedAt,
    viewableByPermissions: attributes?.viewableByPermissions || [],
    workflowGuuid: v2.workflowId,
    workflowType,
    placementTypes_text_full_match: attributes?.placementTypes_text_full_match || [],
    keyword_text_general: attributes?.keyword_text_general || [],
    classifier_text_general: attributes?.classifier_text_general || [],
    project_text_general: attributes?.project_text_general || [],
    request_type_text_general: attributes?.request_type_text_general || [],
    lob_text_full_match: attributes?.lob_text_full_match || [],
    revision_round_integer: attributes?.revision_round_integer || [],
    backstage_task_id_text_general: attributes?.backstage_task_id_text_general || []
  };

  return v1;
}
