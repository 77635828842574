import { WorkflowType } from '@helper-hooks/user-preferences-redux-action';
import { PERMISSIONS } from '../../../../common';
import { FormType, WorkflowSettings } from '../../../../common/types/UserPreferences';

export const BetaFeaturesConfig = [
  {
    workflowType: WorkflowType.MUSIC,
    namespacePermissions: [PERMISSIONS.mcwCanAccessNamespace],
    features: []
  },
  {
    workflowType: WorkflowType.VIDEO,
    namespacePermissions: [PERMISSIONS.vcwCanAccessNamespace],
    features: []
  },
  {
    workflowType: WorkflowType.COPY,
    namespacePermissions: [],
    features: [
      {
        propertyKey: 'sseUserTracking',
        type: FormType.RADIO,
        permissions: [],
        label: 'User Tracking',
        prodEnabled: false,
        defaultValue: false,
        options: [
          {
            value: false,
            label: 'Disable'
          },
          {
            value: true,
            label: 'Enable'
          }
        ],
        localChange: false,
        localChangeString: ''
      }
    ]
  },
  {
    workflowType: WorkflowType.WORKLIST,
    namespacePermissions: [PERMISSIONS.canViewWorkflowBetaFeatures],
    prodEnabled: false,
    features: []
  }
] as WorkflowSettings[];
